import { Link } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { PostType } from './types';
import { Typography } from '@/components/Typography';
import './Post.scss';

const Post = ({ date, title, description, slug, image }: PostType) => {
  const lazyImage = getImage(image);

  const trimmedTitle =
    title.length > 50 ? title.substring(0, 50) + '...' : title;

  return (
    <Link
      to={`/blog/${slug}`}
      className="latest-post col-lg-4 col-md-6 col-sm-12"
    >
      {/* <div className={'latest-post col-lg-4 col-md-6 col-sm-12'}> */}
      <Typography
        component={'span'}
        typographyKind={'text-secondary-small'}
        className={'latest-posts__post-date'}
        size={14}
        margin={[0]}
        lineHeight={16}
      >
        {date}
      </Typography>
      <div className="blog-thumbnail">
        <GatsbyImage
          image={lazyImage || ({} as IGatsbyImageData)}
          alt={title}
        />
      </div>
      <Typography
        component={'h4'}
        margin={[2, 0]}
        typographyKind={'header-secondary-small-bold'}
        className="text-red-on-hover"
        size={24}
      >
        {trimmedTitle}
      </Typography>

      <Typography
        component={'p'}
        margin={[0]}
        lineHeight={24}
        size={18}
        typographyKind={'text-secondary-medium'}
        className={'latest-post__description'}
      >
        {description.substring(0, 160)}...
      </Typography>
      {/* <Link
        className={'flex-row flex-align-items-center latest-post__link-wrapper'}
        to={`/blog/${slug}`}
      >
        <Typography
          component="p"
          typographyKind={'text-primary-medium-medium'}
          className={'latest-post__link text-red-on-hover'}
        >
          Read more
          <RightIcon />
        </Typography>
      </Link> */}
      {/* </div> */}
    </Link>
  );
};

export default Post;
