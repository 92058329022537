import { useEffect } from 'react';
import { graphql } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import PostContent from './PostContent';
import LatestPosts from '@/components/LatestPosts';
import Layout from '@/components/Layout';
import { BlogPostQueryType, PageContextDTO } from '@/sections/Blog/types';
import { HomePageCTA } from '@/sections/Home/CTA';
import { resourcesPage } from '@/utils/contentData/resourcesPage';

const Index = ({ data: { mdx } }: BlogPostQueryType & PageContextDTO) => {
  const featuredImage = getImage(mdx.frontmatter.featuredImageData);
  const authorThumbnail = getImage(mdx.frontmatter.authorThumbnailData);

  const changeTargetForAnchor = () => {
    const allAnchor: Element[] = Array.from(
      document.getElementsByClassName('blog-heading-anchor')
    );

    allAnchor.forEach((item) => {
      item.setAttribute('target', '_self');
    });
  };

  useEffect(() => {
    changeTargetForAnchor();
  }, []);

  return (
    <Layout
      image={featuredImage?.images.fallback?.src}
      title={mdx.frontmatter.title}
      description={mdx.frontmatter.description}
      blogAuthor={mdx.frontmatter.author}
      ogTypeMetaTag={'article'}
    >
      <PostContent
        publishedAt={mdx.frontmatter.date}
        author={mdx.frontmatter.author}
        authorProfileUrl={mdx.frontmatter.authorProfileUrl}
        authorThumbnail={authorThumbnail as IGatsbyImageData}
        authorThumbnailAlt={mdx.frontmatter.authorThumbnailAlt}
        title={mdx.frontmatter.title}
        featuredImage={featuredImage as IGatsbyImageData}
        featuredImageAlt={mdx.frontmatter.featuredImageAlt}
        body={mdx.body}
      />
      <LatestPosts blog />
      <HomePageCTA
        header={resourcesPage.cta.title}
        subheader={resourcesPage.cta.subTitle}
      />
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String) {
    mdx(id: { eq: $id }) {
      excerpt(pruneLength: 160)
      body
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        author
        authorProfileUrl
        description
        urlImageSeo
        title
        featuredImageData {
          childImageSharp {
            gatsbyImageData
          }
        }
        featuredImageAlt
        authorThumbnailData {
          childImageSharp {
            gatsbyImageData
          }
        }
        authorThumbnailAlt
      }
    }
  }
`;
