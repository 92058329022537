import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { LinkedinIcon, LinkedinShareButton } from 'react-share';
import { SRLWrapper } from 'simple-react-lightbox';
import { BlogPostContentType } from './types';
import { ShareSocialMediaButtons } from '@/components/ShareSocialMediaButtons';
import { Typography } from '@/components/Typography';
import { TwitterSquareIcon } from '@/static/assets/icons';
import './PostContent.scss';

const PostContent = ({
  publishedAt,
  author,
  authorThumbnail,
  authorThumbnailAlt,
  authorProfileUrl,
  title,
  featuredImage,
  featuredImageAlt,
  body
}: BlogPostContentType) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <div className={'single-blog-post blog-post-padding-global'}>
      <div className="blog-post__header">
        <div className={'author-box__wrapper'}>
          <a
            className="author-image__wrapper"
            href={authorProfileUrl}
            target={'_blank'}
            rel="noreferrer"
          >
            <GatsbyImage
              image={authorThumbnail}
              alt={authorThumbnailAlt}
              className={'author-image'}
            />

            {authorProfileUrl?.includes('linkedin') ? (
              <span
                className={'author-social-icon author-social-icon--linkedin'}
              >
                <LinkedinShareButton url={''} title={'test'}>
                  <LinkedinIcon widths={50} />
                </LinkedinShareButton>
              </span>
            ) : (
              <TwitterSquareIcon className={'author-social-icon'} />
            )}
          </a>
          <div className={'author-data-box'}>
            <Typography
              component={'span'}
              typographyKind={'text-primary-medium-bold'}
              size={16}
              className={'author-name'}
            >
              {author}
            </Typography>
            <Typography
              component={'span'}
              typographyKind={'text-secondary-medium'}
              size={16}
              className={'author-post-date'}
            >
              {publishedAt}
            </Typography>
          </div>
          <ShareSocialMediaButtons
            url={url}
            title={title}
            wrapperClassname="share-on-social-media"
          />
        </div>
        <Typography
          component={'h1'}
          blogTypography
          typographyKind={'header-primary-medium-bold'}
          margin={[8, 0]}
        >
          {title}
        </Typography>
        <GatsbyImage image={featuredImage} alt={featuredImageAlt} />
      </div>
      <div className={'blog-posts-wrapper'}>
        <SRLWrapper>
          <MDXRenderer>{body}</MDXRenderer>
        </SRLWrapper>
        <Typography
          component="span"
          size={16}
          typographyKind={'text-primary-medium-bold'}
        >
          Share this blog:{' '}
        </Typography>
        <ShareSocialMediaButtons
          url={url}
          title={title}
          wrapperClassname="share-on-social-media-footer"
        />
      </div>
    </div>
  );
};

export default PostContent;
