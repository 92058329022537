import Post from './Post';
import { Typography } from '@/components/Typography';
import { useBlogPostsQuery } from '@/hooks/useBlogPostsQuery';
import './LatestPosts.scss';
import { commonComponentsContent } from '@/utils/contentData/commonComponents';

const POSTS_TO_FETCH = 3;

const { title } = commonComponentsContent.blog;

const LatestPosts = ({ blog }: { blog?: boolean }) => {
  const { postsList } = useBlogPostsQuery(POSTS_TO_FETCH);

  const getBlogPosts = () =>
    postsList &&
    postsList.map(
      (
        { slug, excerpt, frontmatter: { id, date, title, featuredImageData } },
        idx
      ) => (
        <Post
          key={`${id}=${idx}`}
          id={id}
          date={date}
          title={title}
          description={excerpt}
          slug={slug}
          image={featuredImageData}
        />
      )
    );

  return (
    <div
      className={`${
        blog
          ? 'blog-latest-posts content--max-width'
          : 'latest-posts content--max-width'
      }`}
    >
      <Typography
        component={'h2'}
        typographyKind={'header-secondary-large-bold'}
        margin={[16, 0]}
        className={'latest-posts__header'}
        size={36}
      >
        {title}
      </Typography>
      <div className={'latest-posts__container container-fluid'}>
        <div className={'row flex justify-space-around'}>{getBlogPosts()}</div>
      </div>
    </div>
  );
};

export default LatestPosts;
