import { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AllBlogPostsQueryType } from '@/sections/Blog/types';

export const useBlogPostsQuery = (postsToFetch: number) => {
  const {
    allMdx: { nodes }
  }: AllBlogPostsQueryType = useStaticQuery(
    graphql`
      query BlogPosts {
        allMdx(sort: { fields: frontmatter___date, order: DESC }) {
          nodes {
            slug
            excerpt(pruneLength: 160)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              author
              authorProfileUrl
              title
              featuredImageData {
                childImageSharp {
                  gatsbyImageData
                }
              }
              featuredImageAlt
              authorThumbnailData {
                childImageSharp {
                  gatsbyImageData
                }
              }
              authorThumbnailAlt
            }
          }
        }
      }
    `
  );

  const [postsList, setPostsList] = useState([...nodes.slice(0, postsToFetch)]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMoreToLoad, setHasMoreToLoad] = useState(
    nodes.length > postsToFetch
  );

  useEffect(() => {
    if (loadMore && hasMoreToLoad) {
      const currentLength = postsList.length;
      const isMore = currentLength < nodes.length;
      const nextResults = isMore
        ? nodes.slice(currentLength, currentLength + postsToFetch)
        : [];
      setPostsList([...postsList, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMoreToLoad]);

  useEffect(() => {
    const isMoreToLoad = postsList.length < nodes.length;
    setHasMoreToLoad(isMoreToLoad);
  }, [postsList]);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  return { postsList, hasMoreToLoad, handleLoadMore };
};
