import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';

export type ShareSocialMediaButtonsProps = {
  url: string;
  title: string;
  wrapperClassname?: string;
};

export const ShareSocialMediaButtons = ({
  url,
  title,
  wrapperClassname
}: ShareSocialMediaButtonsProps) => (
  <div className={wrapperClassname}>
    <TwitterShareButton url={url} title={`${title} via @akuityio`}>
      <TwitterIcon />
    </TwitterShareButton>

    <LinkedinShareButton url={url} title={title}>
      <LinkedinIcon />
    </LinkedinShareButton>

    <FacebookShareButton url={url} title={title}>
      <FacebookIcon />
    </FacebookShareButton>
  </div>
);
